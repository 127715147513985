// @flow

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import axios, { type Axios } from 'axios'
import ClientOAuth2 from 'client-oauth2'
import url from 'url'
import PageNotFoundErrorPage from './GuidelinesNew/PageNotFoundErrorPage'
import { type ProgramPayload, PACKS, fetchProgram } from './ProgramPreview/Api'
import LoadingScreen from './GuidelinesNew/LoadingScreen'
import CustomerAreaBridge from './CustomerAreaBridge'
import CustomerContext from './CustomerAreaBridge/Context'
import RotateDevice from './GuidelinesNew/RotateDevice'
import history from './history'
import { type REFERRER_VALUE } from './urls'
import {
  GoogleAnalyticsService,
  useGlobalGoogleAnalyticsFunction,
} from './GoogleAnalytics'
import './i18n'
import i18next from 'i18next'

const ProgramPreview = React.lazy(() => import('./ProgramPreview/UI'))
const CustomerArea = React.lazy(() => import('./CustomerArea'))
const Configurateur3DApplication = React.lazy(() =>
  import('./FlatConfigurator/Configurateur3DApplication'),
)
const ConfigurationScreenshotCapture = React.lazy(() =>
  import('./ConfigurationScreenshotCapture'),
)

type Props = {|
  apiEndpoint: string,
  oauthClientId: string,
  oauthClientSecret: string,
|}

type State = {|
  httpClient: Axios,
  authClient: ClientOAuth2,
  googleAnalyticsService: GoogleAnalyticsService,
  program: ?ProgramPayload,
|}

class MainApplication extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const httpClient = axios.create({
      baseURL: props.apiEndpoint,
    })

    // The browser version of the `url` module implement the deprecated version of the `url` API
    const accessTokenUri = url.format({
      ...url.parse(props.apiEndpoint || '/'), // eslint-disable-line node/no-deprecated-api
      ...{ pathname: '/oauth/v2/token' },
    })

    const authClient = new ClientOAuth2({
      clientId: props.oauthClientId,
      clientSecret: props.oauthClientSecret,
      accessTokenUri,
      scopes: [],
    })

    const googleAnalyticsService = new GoogleAnalyticsService(
      useGlobalGoogleAnalyticsFunction(),
    )

    this.state = {
      httpClient,
      authClient,
      googleAnalyticsService,
      program: null,
    }
  }

  async componentDidMount() {
    const { httpClient, googleAnalyticsService } = this.state

    httpClient.interceptors.request.use(config => ({
      ...config,
      params: {
        locale: i18next.language,
      },
    }))

    const program = await fetchProgram(httpClient)
    document.title = program.label

    this.setState({ program })

    googleAnalyticsService.listenToLocationChange(history)
  }

  render() {
    const {
      httpClient,
      authClient,
      googleAnalyticsService,
      program,
    } = this.state

    if (!program) {
      return <LoadingScreen isVisible />
    }

    return (
      <CustomerAreaBridge
        httpClient={httpClient}
        authClient={authClient}
        googleAnalyticsService={googleAnalyticsService}
        programId={program.programId}
        privacyPoliciesPageUrl={program.links.privacy_policy}
        termsOfServicesPageUrl={program.links.terms_of_use}
      >
        <RotateDevice />
        <React.Suspense fallback={<LoadingScreen isVisible />}>
          <Switch>
            {/* Redirect to the homepage. Only for development environment. The production redirection is configured in the now.json file */}
            <Route exact path="/" render={() => <Redirect to="/program" />} />

            <Route
              exact
              path="/program"
              render={() => (
                <CustomerContext.Consumer>
                  {customerContextApi => (
                    <ProgramPreview
                      httpClient={httpClient}
                      customerContextApi={customerContextApi}
                      program={program}
                    />
                  )}
                </CustomerContext.Consumer>
              )}
            />

            <Route
              exact
              path="/lot/:lotId"
              render={props => {
                const searchParams = new URLSearchParams(props.location.search)
                const configurationId = searchParams.get('configurationId')

                const referrer: REFERRER_VALUE =
                  // $FlowFixMe: Convert a string to an enum is impossible in flow
                  searchParams.get('referrer') || null

                return (
                  <CustomerContext.Consumer>
                    {customerContextApi => (
                      <Configurateur3DApplication
                        httpClient={httpClient}
                        customerContextApi={customerContextApi}
                        programId={program.programId}
                        pack={program.pack}
                        lotId={props.match.params.lotId}
                        configurationId={configurationId}
                        referrer={referrer}
                        history={props.history}
                        privacyPoliciesPageUrl={program.links.privacy_policy}
                      />
                    )}
                  </CustomerContext.Consumer>
                )
              }}
            />

            {program.pack === PACKS.BUILDING_3D_CONFIGURATOR &&
              !process.env.REACT_APP_DISABLE_CLIENT_AREA && (
                <Route
                  path="/customer"
                  render={() => (
                    <CustomerContext.Consumer>
                      {customerContextApi => (
                        <CustomerArea
                          httpClient={httpClient}
                          customerContextApi={customerContextApi}
                          privacyPoliciesPageUrl={program.links.privacy_policy}
                          programDeveloperName={program.developerName}
                          programLabel={program.label}
                          programLocation={program.location}
                          programLotsNumber={program.numberOfLots}
                        />
                      )}
                    </CustomerContext.Consumer>
                  )}
                />
              )}

            <Route
              exact
              path="/screenshot/:lotId"
              render={props => (
                <ConfigurationScreenshotCapture
                  httpClient={httpClient}
                  programId={program.programId}
                  lotId={props.match.params.lotId}
                />
              )}
            />

            <Route render={() => <PageNotFoundErrorPage />} />
          </Switch>
        </React.Suspense>
      </CustomerAreaBridge>
    )
  }
}

export default MainApplication
