// @flow

import Customer from '../CustomerArea/Customer'
import ConfigurationShoppingCart from '../FlatConfigurator/MainMenu/Configuration/ShoppingCart/ConfigurationShoppingCart'
import {
  type ConfigurationsPayload,
  type ConfigurationPayload,
  type ConfigurationReceiptPayload,
} from '../CustomerArea/api'
import { CustomerContextApi } from './CustomerContextApi'

export default class NullCustomerContextApi implements CustomerContextApi {
  customer: ?Customer

  replaceCustomer(newCustomer: ?Customer): CustomerContextApi {
    throw new Error('The CustomerContext is not initialised.')
  }

  isAuthenticated(): boolean {
    throw new Error('The CustomerContext is not initialised.')
  }

  startRegistrationProcess(
    shoppingCart?: ConfigurationShoppingCart,
  ): Promise<?Customer> {
    throw new Error('The CustomerContext is not initialised.')
  }

  registerCustomer(
    programId: string,
    firstName: string,
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }> {
    throw new Error('The CustomerContext is not initialised.')
  }

  startLoginProcess(cancelable?: boolean): Promise<?Customer> {
    throw new Error('The CustomerContext is not initialised.')
  }

  logInCustomer(email: string, password: string): any {
    throw new Error('The CustomerContext is not initialised.')
  }

  logOutCustomer(): any {
    throw new Error('The CustomerContext is not initialised.')
  }

  saveShoppingCart(shoppingCart: ConfigurationShoppingCart): Promise<string> {
    throw new Error('The CustomerContext is not initialised.')
  }

  fetchShoppingCartByLotId(lotId: string): Promise<ConfigurationShoppingCart> {
    throw new Error('The CustomerContext is not initialised.')
  }

  fetchConfigurations(): Promise<ConfigurationsPayload> {
    throw new Error('The CustomerContext is not initialised.')
  }

  fetchConfiguration(): Promise<ConfigurationPayload> {
    throw new Error('The CustomerContext is not initialised.')
  }

  fetchConfigurationReceiptInJson(
    configurationId: string,
  ): Promise<ConfigurationReceiptPayload> {
    throw new Error('The CustomerContext is not initialised.')
  }

  fetchConfigurationReceiptInPdfDownloadUrl(
    configurationId: string,
  ): Promise<string> {
    throw new Error('The CustomerContext is not initialised.')
  }

  deleteConfiguration(configurationId: string): Promise<any> {
    throw new Error('The CustomerContext is not initialised.')
  }

  refreshCustomer(): Promise<Customer> {
    throw new Error('The CustomerContext is not initialised.')
  }

  configurationCustomerContact(
    configurationId: string,
    firstName: string,
    lastName: string,
    phone: string,
  ): Promise<Array<{| field: ?string, message: string |}>> {
    throw new Error('The CustomerContext is not initialised.')
  }

  editProfilCustomer(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
  ): Promise<Array<{| field: ?string, message: string |}>> {
    throw new Error('The CustomerContext is not initialised.')
  }

  editPasswordCustomer(
    oldPassword: string,
    newPassword: string,
  ): Promise<Array<{| field: ?string, message: string |}>> {
    throw new Error('The CustomerContext is not initialised.')
  }
}
